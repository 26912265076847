import React from 'react';

import ContactForm from './components/ContactForm';
import Container from 'atoms/Container';

export interface Props {}

const Contact: React.FC<Props> = () => {
  return (
    <Container className={`Contact`}>
      <ContactForm />
    </Container>
  );
};

export default Contact;
