import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageProps } from 'gatsby-image';

import Layout from 'molecules/Layout';
import Contact from 'organisms/Contact';

import 'styles/pages/kontakt.scss';

export const CONTACT_QUERY = graphql`
  query ContactQuery {
    logo: file(relativePath: { eq: "logos/thamarai-kontakt-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface Data {
  logo: {
    childImageSharp: GatsbyImageProps;
  };
}

interface Props {}

const KontaktPage: React.FC<Props> = () => {
  const { logo } = useStaticQuery<Data>(CONTACT_QUERY);

  return (
    <Layout
      className="ContactPage"
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/kontakt-aufnehmen"
          canonical="/kontakt-aufnehmen"
          title="Kontakt"
          description="Haben Sie etwas auf dem Herzen? Schreiben Sie uns!"
          lang="de-DE"
        />
      )}
      header={() => (
        <Layout.Header
          title="Kontakt"
          subtitle="Haben Sie etwas auf dem Herzen? Schreiben Sie uns!"
          image={logo.childImageSharp}
          alt="Exklusives Logo für die Kontaktseite"
        />
      )}
    >
      <Contact />
    </Layout>
  );
};

export default KontaktPage;
