import React from 'react';
import axios from 'axios';

import Button from '@soosap/sushi/Button';
import Form, { FormSize } from '@soosap/sushi/Form';
import EmailField from '@soosap/sushi/Form/EmailField';
import TextAreaField from '@soosap/sushi/Form/TextAreaField';
import FieldSet from '@soosap/sushi/FieldSet';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';

interface Props {}

const ContactForm: React.FC<Props> = () => {
  const { addToast } = useToasts();
  const { GATSBY_BACKEND_URL } = process.env;

  const onSubmit = async (values: object) => {
    try {
      const res = await axios.post(`${GATSBY_BACKEND_URL}/api/contact`, values);
      addToast(res.data, {
        theme: ToastTheme.SUCCESS,
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validateOnBlur
      render={({ handleSubmit, form }) => {
        return (
          <form
            className="ContactForm"
            onSubmit={event => {
              if (handleSubmit) {
                (handleSubmit(event) as Promise<object>).then(form.reset);
              }
            }}
          >
            <FieldSet className="ContactForm__field-set">
              <EmailField
                name="email"
                placeholder="z.B. yann.tiersen@web.de"
                size={FormSize.SMALL}
                required
              />
              <TextAreaField
                name="message"
                size={FormSize.SMALL}
                placeholder="Was liegt Ihnen auf dem Herzen?"
                label="Ihre Nachricht"
                required
              />
              <Button type="submit">Absenden</Button>
            </FieldSet>
          </form>
        );
      }}
    />
  );
};

export default ContactForm;
